/**
 * Universal globals
 * - Only for values shared between services
 * - Services must be configured to compile with Webpack
 */

/**
 * NB: Nothing PROJECT_ID-dependent should be in here!
 * - If a var _is_ PROJECT_ID-dependent, make a function, like with datascienceQueryingUrl
 */
import { LanguagePreference } from '@nicejob-library/data-platform-implementation/entities/User/enums';

export const Online = process.env.NODE_ENV === 'production';
export const NICEJOB_CLICK_ID_PARAM = 'njclid';
export const ENCRYPTED_SESSION_ID_PARAM = 'njesid';
export const DEFAULT_COMPANY_NAME = 'My New Company';
export const DEFAULT_LANGUAGE_PREFERENCE = LanguagePreference.EN;

//  Project IDs
export const NICEJOB_OPS_PROJECT_ID = `nicejob-ops-id`;
export const NICEJOB_PRODUCTION_PROJECT_ID = 'nicejob-production';

//  Company ID for the production NiceJob company
export const NICEJOB_COMPANY_ID = `6576506855751680`;

//  Company ID for the EXP Andrew's Bricklaying company
export const BRICKLAYING_COMPANY_ID = `5688172348440576`;

// Company ID for feature proejct SDK Company
export const FEATURE_PROJECT_SDK_COMPANY_ID = '5376305005592576';

export const TESTING_COMPANY_ID = '5664694119432192';

//  Product names
export const CONVERT_LANDING_PAGES = 'convert_landing_pages';

//  Enable / disable
export const ENABLE_TIERED_PRICING = true;

/**
 * REST-to-GraphQL property mapping
 * - This is for graphql-route REST-to-GraphQL property conversion
 * - This is NOT for Campaign rules or filters
 */
export const REST_GQL_PROPERTY_MAPPINGS: {
    [kind: string]: {
        [property: string]: string;
    };
} = {
    /**
     * Mapping nested fields example:
     * Person: {
     *     'address.state': 'address.province'
     * }
     */
    Job: {
        external_created_at: 'created_at',
        scheduled_at: 'scheduled_time',
        completed_at: 'completed_time',
    },
    Visit: {
        external_created_at: 'created_at',
        job_id: 'booking_id',
        scheduled_at: 'scheduled_time',
        completed_at: 'completed_time',
    },
    Case: {
        external_created_at: 'created_at',
        completed_at: 'completed_time',
    },
    Invoice: {
        external_created_at: 'created_at',
        external_invoice_name: 'name',
    },
    Payment: {
        external_created_at: 'created_at',
    },
    Proposal: {
        external_created_at: 'created_at',
        completed_at: 'completed_time',
    },
};

//  Map inputs to types
const INPUT_MAP: {
    [input: string]: Array<string>;
} = {
    Case: ['CreateCaseInput', 'UpdateCaseInput'],
    Invoice: ['CreateInvoiceInput', 'UpdateInvoiceInput'],
    Job: ['CreateJobInput', 'UpdateJobInput'],
    Visit: ['CreateVisitInput', 'UpdateVisitInput'],
    Payment: ['CreatePaymentInput', 'UpdatePaymentInput'],
    Proposal: ['CreateProposalInput', 'UpdateProposalInput'],
};

for (const k in INPUT_MAP) {
    for (const input of INPUT_MAP[k]) {
        REST_GQL_PROPERTY_MAPPINGS[input] = REST_GQL_PROPERTY_MAPPINGS[k];
    }
}
/**
 * Local ports
 */
export const DEFAULT_APPENGINE_PORT = 8080;
export const LOGIN_ROUTE_LOCAL_PORT = 8088;
export const DEVELOPER_ROUTE_LOCAL_PORT = 8096;
export const INBOUND_OAUTH2_ROUTE_LOCAL_PORT = 8092;
export const OUTBOUND_OAUTH2_ROUTE_LOCAL_PORT = 8091;
export const INTERNAL_GRAPHQL_ROUTE_LOCAL_PORT = 8084;
export const EXTERNAL_GRAPHQL_ROUTE_LOCAL_PORT = 8094;
export const TRACKING_ROUTE_LOCAL_PORT = 8098;
export const ENG_ROUTE_LOCAL_PORT = 8099;
export const MICROSITE_ROUTE_LOCAL_PORT = 8093;
export const WEBHOOK_ROUTE_LOCAL_PORT = 8089;
export const SDK_ROUTE_LOCAL_PORT = 8095;
export const FRONTEND_ROUTE_LOCAL_PORT = 3000;

export const APPENGINE_ROUTE_PORTS: Record<string, number> = {
    'default-route': DEFAULT_APPENGINE_PORT,
    'login-route': LOGIN_ROUTE_LOCAL_PORT,
    'developer-route': DEVELOPER_ROUTE_LOCAL_PORT,
    'eng-route': ENG_ROUTE_LOCAL_PORT,
    'inbound-oauth2-route': INBOUND_OAUTH2_ROUTE_LOCAL_PORT,
    'microsite-route': MICROSITE_ROUTE_LOCAL_PORT,
    'outbound-oauth-route': OUTBOUND_OAUTH2_ROUTE_LOCAL_PORT,
    'tracking-route': TRACKING_ROUTE_LOCAL_PORT,
    'webhook-route': WEBHOOK_ROUTE_LOCAL_PORT,
    'sdk-route': SDK_ROUTE_LOCAL_PORT,
    'graphql-route-internal': INTERNAL_GRAPHQL_ROUTE_LOCAL_PORT,
    'graphql-route-external': EXTERNAL_GRAPHQL_ROUTE_LOCAL_PORT,
    'frontend-route': FRONTEND_ROUTE_LOCAL_PORT,
};

export const DEFAULT_LOCAL_REACT_SERVER_PORT = 3010;
export const DEVELOPER_ROUTE_REACT_SERVER_PORT = 3011;

/**
 * tracking-route routing
 */
const TRACKING_PATHS = ['route', 'o'] as const;
type TrackingPath = typeof TRACKING_PATHS[number];
export function getTrackingPathForProjectKey(project_key?: string): TrackingPath {
    return project_key === 'production' ? 'o' : 'route';
}

//  Paths, with legacy paths (thru, unsub, read)
export type TrackingRoutePath = 'thru' | 'read' | 'unsub' | 't' | 'v' | 'u' | 's';
export const CLICK_PATHS: Array<TrackingRoutePath> = ['t', 'thru'];
export const UNSUBSCRIBE_PATHS: Array<TrackingRoutePath> = ['u', 'unsub'];
export const VIEW_PATHS: Array<TrackingRoutePath> = ['v', 'read'];
export const SHARE_PATH: TrackingRoutePath = 's';

/**
 * Validation
 */
export const VALID_EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const VALID_PHOTO_FILE_TYPES = ['image/jpg', 'image/jpeg', 'image/png'];

/**
 * Patterns
 */
export const FRIENDLY_EXTRACT_PATTERN = / *\[ *friendly *\][ :]*(.+)/;
export const FRIENDLY_REPLACE_PATTERN = / *\[ *friendly *\][ :]*/;
export const INVALID_EXTRACT_PATTERN = / *\[ *invalid *\][ :]*(.+)/;
export const INVALID_REPLACE_PATTERN = / *\[ *invalid *\][ :]*/;

/**
 * Campaign IDs
 */
export const GET_REVIEWS_CAMPAIGN_ID = 'get_reviews';
export const GET_REVIEWS_HIDDEN_CAMPAIGN_ID = 'get_reviews_hidden';
export const RECOMMENDATIONS_CAMPAIGN_ID = 'recommendations';
export const BOOKINGS_CAMPAIGN_ID = 'bookings';

/**
 * Reserved paths
 * - These URL paths are reserved for specific AppEngine routes, and cannot be used either as frontend-route paths, or as microsite slugs
 * - THEREFORE all entries must also be added to the `PROTECTED_SLUGS` array in .constants.json
 * - These values should correspond to dispatch.yaml routing entries, e.g. `* /hook/*` for the webhook-route
 */
export const RESERVED_URL_PATHS: Array<string> = [
    'auth',
    'sdk',
    'hook',
    'oauth',
    'graphql',
    'subscriptions',
    'docs',
    'login',
    'u',
    ...TRACKING_PATHS,
];

//  RESERVED_URL_PATH_PATTERNS includes dispatch.yaml entries with a wildcard path
export const RESERVED_URL_PATH_PATTERNS: Array<RegExp> = [/^doc/, /^subscription/];

/**
 * Frontend @apollo/client header values
 */
export const APOLLO_CLIENT_CSRF_HEADER = 'X-CSRF-TOKEN';
export const APOLLO_CLIENT_SDK_SESSION_ID_HEADER = 'X-NJ-SID';

/**
 * iOS app header values
 */
export const IOS_CLIENT_IDENTIFYING_HEADER = `nicejob-ios-app`;

/**
 * Default MessageTemplate IDs
 */
export const DEFAULT_MESSAGE_TEMPLATE_SMS = `get-reviews-0__thanks`;
export const DEFAULT_MESSAGE_TEMPLATE_EMAIL_STANDARD = `get-reviews-1__recommend`;
export const DEFAULT_MESSAGE_TEMPLATE_EMAIL_PLAIN = `get-reviews-3__personal`;

/**
 * Google Tag Manager
 */
export const GOOGLE_TAG_MANAGER_ID = Online ? 'GTM-PTJG8DK' : null;

/**
 * Cloud Function
 */
export const MAX_CLOUD_FUNCTION_TIMEOUT_SECONDS = 540;
export const MAX_CLOUD_FUNCTION_TIMEOUT_STRING = `${MAX_CLOUD_FUNCTION_TIMEOUT_SECONDS}s` as const;

/** Product Fees */
export const LANDING_PAGE_MONTHLY_FEE = 1500;
export const LANDING_PAGE_SETUP_FEE = 0;
export const CONVERT_SETUP_FEE = 9900;
export const REPEAT_BUSINESS_CAMPAIGN_MONTHLY_FEE = 2000;
export const REPEAT_BUSINESS_CAMPAIGN_SETUP_FEE = 0;

/** URLs */
export const NICEJOB_HELP_CENTER_URL = 'https://help.nicejob.com';
export const ATOM_HELP_CENTER_URL = 'https://help.atomhq.com';

/** Google Analytics Labels: product name, do not translate */
export const GA_4 = 'Google Analytics 4';
export const UA = 'Universal Analytics';

/** Google Analytics Leads Event name */
export const GA_LEADS_EVENT_NAME = 'receive_lead';

export const BRANDS = {
    Paystone: {
        name: 'Paystone',
        emailSupportAccount: 'support@paystone.com',
        appIconImgUrlPath: '/assets/paystone-inline-small-logo.png',
        emailIconImgUrlPath: '/assets/paystone-email-mail-icon.png',
        actionButtonColor: '#1874FD',
    },
    NiceJob: {
        name: 'NiceJob',
        emailSupportAccount: 'support@nicejob.co',
        appIconImgUrlPath: '/assets/nicejob-inline-small-logo-v2.png',
        emailIconImgUrlPath: '/assets/email-mail-icon.png',
        actionButtonColor: '#6D5ACA',
        campaignFromEmail: 'no-reply@campaigns.nicejob.co',
    },
    Atom: {
        name: 'Atom',
        email: 'no-reply@atomhq.com',
        onboardingEmail: 'hello@atomhq.com',
        address: '509 Commissioners Rd. W - Unit 434, London, ON, Canada, N6J 1Y5',
        supportPhone: '1-855-899-4599',
        emailSupportAccount: 'support@atomhq.com',
        privacyUrl: 'https://atomhq.com/privacy',
        appIconImgUrlPath: '/assets/atom-banner.png',
        faviconImgUrlPath: '/assets/atom-logo.svg',
        poweredByImgUrlPath: '/assets/powered-by-atom.png',
        salesDomain: 'https://atomhq.com',
    },
};

export const MOMENT_LOCALE_CONFIG = {
    fr: {
        relativeTime: {
            m: '1 minute',
            h: '1 heure',
            d: '1 jour',
            M: '1 mois',
            y: '1 an',
        },
    },
    en: {},
};

// Common errors
export const ERROR_DUPLICATE_EMAIL_IN_NETWORK = 'DUPLICATE_EMAIL_IN_NETWORK';
